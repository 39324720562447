
.header {
	background-color: #e0e0e0;
	height: 10vh;
	max-height: 4.4rem;
	min-height: 3.8rem;
	display: flex;
}

.header__main-site-link {
	align-self: center;
	margin-right: 3vw;
	position: absolute;
	right: 0vw;

}

.header__main-site-link button {
	background-color: rgba(48, 112, 196, 0.8);
	color: white;
	padding: 0.6rem;
	border-radius: 3px;
	font-size: $font-size-small;
	text-decoration: none;
}