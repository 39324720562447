
.load-spinner {
    min-height: 60vh;
    text-align: center;
}

.load-spinner__spinner {
    margin-bottom: $m-size;
    padding-top: $l-size;
}

.load-spinner__text {

}