
.d3-tip {
  position: fixed;
  line-height: 1;
  padding: 0.6rem;
  background: rgba(255, 255, 255, 0.9);
  color: black;
  border-radius: 3px;
  border: 1px solid black;
  pointer-events: none;
}

.d3-tip__grid {
  display: grid;
  // grid-template-columns: 2fr 1fr;
  // grid-template-columns: max-content auto;
  grid-template-columns: 1fr;
  grid-gap: 0.2rem 0.5rem;
}

.d3-tip__header {
  margin-bottom: 3px;
  justify-self: center;
  font-weight: bold;
}

.mcd-callout {
  font-weight: normal;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  position: absolute;
  pointer-events: none;

  @media screen and (max-width: $mobile-breakpoint) {
    display: none;
  }
}

/* Northward tooltips */
.d3-tip.n:after {
  content: "\25BC";
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
  text-align: center;
}

/* Eastward tooltips */
.d3-tip.e:after {
  content: "\25C0";
  margin: -4px 0 0 0;
  top: 50%;
  left: -8px;
}

/* Southward tooltips */
.d3-tip.s:after {
  content: "\25B2";
  margin: 0 0 1px 0;
  top: -8px;
  left: 0;
  text-align: center;
}

/* Westward tooltips */
.d3-tip.w:after {
  content: "\25B6";
  margin: -4px 0 0 -1px;
  top: 50%;
  left: 100%;
}

.tip-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 1rem;
  padding: 0.3rem;
}

.tip-grid div {
  padding: .2rem;
  margin-bottom: .1rem;
  justify-self: right;
}

.tip-grid .year-header {
  font-weight: bold;
  justify-self: center;
}

.tip-grid #outside-metro-header {
  background-color: rgba(44, 160, 44, 0.8);
  width: 100%;
}

.tip-grid #suburban-header {
  background-color: rgba(255, 127, 14, 0.8);
  width: 100%;
}

.tip-grid #urban-header {
  background-color: rgba(31, 119, 180, 0.8);
  width: 100%;
}