div.inset-wrapper {
  position: absolute;
  left: 88px;
}

@media only screen and (max-width: $mobile-breakpoint) {
  div.inset-wrapper {
    display: none;
  }
}
