.legend {
  max-width: 30rem;
  position: absolute;
  z-index: 10;
  padding: $xs-size;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.6);

  right: $xl-size;
  top: 1rem;

  @media screen and (max-width: $mobile-breakpoint) {
    right: 50%;
    bottom: 5px;
    top: unset;
    transform: translateX(50%);
  }
}

.legend__rect {
  padding: 0 5px;
  // width: 300px;
  // height: 20px;
}

.legend__message {
  margin-top: 3px;
  font-size: $font-size-small;
}

.legend__gradient {
}

.legend__svg {
  // height: 30px;
  // width: 310px;
}

.legend .tick {
  // font-size: $font-size-xs;
}
