.map-wrapper {
    display: flex;
    // justify-content: center;
    width: 100%;
    margin: auto;
    flex-direction: column;
    text-align: center;
}


#legend-message {
    // margin-left: $xl-size;
}

.map-wrapper__map {
    // margin: auto;
    padding: 0 $l-size;
    // max-width: 80%;
    width: 50%;
    margin: auto;

    @media screen and (max-width: $mobile-breakpoint){
        width: 90%;
        padding: unset;
    }
}

// .map-wrapper__background-tile {
//     position: absolute;
//     width: 50%;
//     margin: auto;
//     padding: 0 $l-size;
//     z-index: -1;
// }

.map-wrapper__map svg {
    max-height: 80vh;
}