
.stacked-bar-chart-tile {
    width: 90vw;
    margin: 2rem auto 0 auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
}

.stacked-bar-chart {
    justify-self: center;
    // width: 100%;
    // height: 35px;
    // display: grid;
    // grid-template-columns: 22rem 1fr;
    margin-bottom: 0.5rem;
}

.stacked-bar__label {
    font-size: 0.8rem;
}

.stacked-bar-chart__header {
    margin: auto;
}

// .stacked-bar-chart__label {
//     padding: 1rem;
//     align-self: center;
//     justify-self: right;
//     font-size: 0.8rem;
// }

.stacked-bar-chart div {
    // height: 45px;
}

.stacked-bar__svg {
    // min-width: 90%;
    // max-height: 100%;
}

.stacked-bar-chart__control-grid {
    margin: 0 auto;
    display: grid;
    column-gap: 1rem;
    grid-template-columns: repeat(3, 20rem);

    @media screen and (max-width: 1000px) {
        max-width: 90vw;
        grid-template-columns: repeat(2, 1fr);
    }
}

.stacked-bar-chart__sort-grid {
    margin-top: 2rem;
    // margin-left: 210px;
    // max-width: 760px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    width: 900px;

    @media screen and (max-width: 1000px) {
        width: 90vw;
    }
}

.stacked-bar-chart__control {
    @media screen and (max-width: 1000px) {
        max-width: 40vw;
    }
}

.stacked-bar-chart__control-hide-mobile {
    @media screen and (max-width: 1000px) {
        display: none;
    }
}

.stacked-bar-chart__sort-by {
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
}

.stacked-bar-chart__sort-by#city {
    color: rgb(31, 119, 180);
}

.stacked-bar-chart__sort-by#suburban {
    color: rgb(255, 127, 14);
}

.stacked-bar-chart__sort-by#outside-msa {
    color: rgb(44, 160, 44);
}

.stacked-bar-chart__show-more {
    width: 15rem;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.5rem;
    margin: auto;
}